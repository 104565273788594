import React, { useState } from "react";
import { useFormikContext } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AssetQuantityField from "../../../views/inventory/requests/AssetQuantityField";

export default function ChangeQuantityModal({ onClose, selectedLocations }) {
  const { values, setFieldValue } = useFormikContext();
  const [tempQuantity, setTempQuantity] = useState(0);

  const handleSubmit = () => {
    selectedLocations.forEach(index => {
      const location = values.locations[index];
      const qty = tempQuantity - location.sub_qty;
      setFieldValue(`locations.${index}.restock_sub_qty`, qty);
    });

    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography fontSize={20} fontWeight={500} color="text.primary" align="center">
          Input New Quantity
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box py={2} display="flex" justifyContent="center">
          <AssetQuantityField
            quantity={tempQuantity}
            onChange={setTempQuantity}
            quantityProps={{
              label: "New Quantity",
              style: { minWidth: 130 },
              InputLabelProps: {
                shrink: true,
              },
              autoFocus: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={tempQuantity === 0}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
