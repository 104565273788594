import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ChangeQuantityModal from "./ChangeQuantityModal";
import ConfirmDeleteModal from "src/components/images/ConfirmDeleteModal";
import SelectNewLocationModal from "./SelectNewLocationModal";
import BulkAddNewLocationsModal from "./bulkAddNewLocations/BulkAddNewLocationsModal";
import useAddNewLocations from "./hooks/useAddNewLocations";

export default function BulkActionsButton({
  selectedLocations,
  onDeleteLocations,
  onUpdateLocations,
  clearSelectedLocations,
  warehouseLocations = [],
}) {
  const [openQuantityModal, setOpenQuantityModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openBulkAddModal, setOpenBulkAddModal] = useState(false);
  const { addNewLocations } = useAddNewLocations(warehouseLocations);

  const handleDeleteLocations = async () => {
    await onDeleteLocations(selectedLocations);
    clearSelectedLocations();
  };

  const handleUpdateLocations = async newLocation => {
    await onUpdateLocations(selectedLocations, newLocation);
  };

  const handleAddNewLocations = state => {
    addNewLocations(
      state.numberOfLocations,
      Math.floor(state.totalUnits / state.numberOfLocations)
    );
  };

  return (
    <>
      <PopupState variant="popover" popupId="bulk-actions-menu">
        {popupState => (
          <>
            <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
              Bulk Actions {selectedLocations.length > 0 ? ` (${selectedLocations.length})` : ""}
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  setOpenBulkAddModal(true);
                  popupState.close();
                }}
              >
                Add New Locations
              </MenuItem>
              <MenuItem
                disabled={selectedLocations.length === 0}
                onClick={() => {
                  setOpenQuantityModal(true);
                  popupState.close();
                }}
              >
                Change Quantity
              </MenuItem>
              <MenuItem
                disabled={selectedLocations.length === 0}
                onClick={() => {
                  setOpenDeleteModal(true);
                  popupState.close();
                }}
              >
                Delete Locations
              </MenuItem>
              <MenuItem
                disabled={selectedLocations.length === 0}
                onClick={() => {
                  setOpenLocationModal(true);
                  popupState.close();
                }}
              >
                Select New Location
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
      {openQuantityModal && (
        <ChangeQuantityModal
          onClose={() => setOpenQuantityModal(false)}
          selectedLocations={selectedLocations}
        />
      )}
      {openDeleteModal && (
        <ConfirmDeleteModal
          title="Delete Locations"
          descr="Are you sure you want to delete the selected locations?"
          onClose={() => setOpenDeleteModal(false)}
          onDelete={handleDeleteLocations}
        />
      )}
      {openLocationModal && (
        <SelectNewLocationModal
          warehouseLocations={warehouseLocations}
          onClose={() => setOpenLocationModal(false)}
          onSubmit={handleUpdateLocations}
        />
      )}
      {openBulkAddModal && (
        <BulkAddNewLocationsModal
          onClose={() => setOpenBulkAddModal(false)}
          onSubmit={handleAddNewLocations}
        />
      )}
    </>
  );
}
